@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@import-normalize;

* {
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
}

*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f5f6f8;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 500;
}

body.modal {
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 800px;
}

ul {
  list-style: none;
}

button {
  background: none;
  cursor: pointer;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

button::-moz-focus-inner {
  border: 0;
}

input,
textarea {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

input:focus,
textarea:focus {
  outline: none;
}
